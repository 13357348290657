<template>
    <div class="c-default-entry-not-found c-default-entry-content container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <header>
                    <h1>{{$t('entryNotFound.title')}}</h1>
                </header>
                <div class="entry-content">
                    <p>{{$t('entryNotFound.text')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DefaultEntryNotFound',
    props: {
        srcMp4: String,
        poster: String,
    },
}
</script>
