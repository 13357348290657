<template>
    <div class="v-default-entry entry-view">
        <div class="view-content">
            <main-navigation />
            <default-entry-content :entry="entry"/>
        </div>
    </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue';
import DefaultEntryContent from '@/components/DefaultEntryContent.vue';

import { fetchDefaultEntryBySlug } from '../http/fetchApi';

export default {
    name: 'DefaultEntry',
    components: {
        MainNavigation,
        DefaultEntryContent
    },
    props: {
        slug: String,
    },
    data() {
        return {
            entry:{},
        }
    },
    mounted() {
        this.fetchEntry();
    },
    methods: {
        async fetchEntry() {
            const { data } = await fetchDefaultEntryBySlug(this.slug);
            this.entry = data[0];

            // set header data
            const contentParagraphs = this.entry.content.match(/<p>([\s\S]*)?<\/p>/i)||[];
            this.$globalData.setHeaderData({
                title: 'F+F 1971 - '+this.entry.title,
                description: contentParagraphs[1] || ''
            });
        },
    },
}
</script>
