<template>
    <div v-if="entry && entry.id" class="c-default-entry-content container-fluid">
        <div ref="mainEntryContent" class="row">
            <div class="col-md-8">
                <header>
                    <h1 ref="pageTitle" v-html="entry.longTitle || entry.title"></h1>
                    <h3 class="subtitle" v-if="entry.subtitle" v-html="entry.subtitle"></h3>
                </header>

                <div id="video" class="row" v-if="entry.videoFileMp4">
                    <div class="col-12">
                        <video-player v-if="entry.videoFileMp4" :poster="entry.videoPosterImage.src" :src-mp4="entry.videoFileMp4.src"/>
                    </div>
                </div>

                <div ref="entryContent" class="entry-content" v-html="entry.content" />
            </div>
            <div ref="sidebarColumn" class="col-md-4 sidebar-col">
                <ul ref="sidebarLinks" class="sidebar-links" :class="{'scrolling-links': sidebarScrolling}" v-if="!isMobile || (isMobile && entry.relatedEntry)">
                    <li v-if="entry.relatedEntry">
                        <router-link class="link-font arrow-right" :to="{ name: relatedEntryRouteName, params: { locale: $route.params.locale, slug: entry.relatedEntry.slug, categorySlug: entry.relatedEntry.categorySlug||null }}">{{$t('defaultEntryContent.sidebar.linkLabels.'+entry.relatedEntry.type, { title: entry.relatedEntry.title })}}</router-link>
                    </li>
                    <li v-if="showToTopLink && !isMobile">
                        <a href="#top" @click="scrollToHash" class="to-top-link link-font arrow-up">{{$t('defaultEntryContent.sidebar.linkLabels.toTop')}}</a>
                    </li>
                </ul>
            </div>
            <div v-if="Object.keys(hoverImages).length" class="hover-preview-images">
                <img v-for="(img, indexKey) in hoverImages" :key="indexKey" v-show="img.show" class="hover-preview-image" :src="img.src" :width="img.width" :height="img.height">
            </div>
        </div>
        <!-- ??? <div class="row d-none d-md-block" v-if="entry.videoFileMp4">
            <div class="col-12">
                <ul class="sidebar-links">
                    <li v-if="entry.relatedEntry">
                        <router-link class="link-font arrow-right" :to="{ name: relatedEntryRouteName, params: { locale: $route.params.locale, slug: entry.relatedEntry.slug, categorySlug: entry.relatedEntry.categorySlug||null }}">{{$t('defaultEntryContent.sidebar.linkLabels.'+entry.relatedEntry.type, { title: entry.relatedEntry.title })}}</router-link>
                    </li>
                </ul>
            </div>
        </div>-->
    </div>
    <default-entry-not-found v-else-if="entry && entry.id === null" />
    <p v-else class="loader-spinner"><span>Loading...</span></p>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import DefaultEntryNotFound from '@/components/DefaultEntryNotFound.vue';

import { fetchArchiveEntryBySlug } from '../http/fetchApi';

export default {
    name: 'DefaultEntryContent',
    components: {
        VideoPlayer,
        DefaultEntryNotFound
    },
    props: {
        entry: Object,
    },
    data() {
        return {
            hoverImages: {},
            internalLinksInited: false,
            lastKnownScrollPosition: 0,
            scrollTicking: false,
            showToTopLink: false,
            contentImageRotation: 20,
            contentImagesInited: false,
            sidebarScrolling: false,
            isMobile: false,
            resizeT: false,
        }
    },
    computed: {
        relatedEntryRouteName() {
            if (this.entry.relatedEntry.type||false) {
                const type = this.entry.relatedEntry.type;
                return (type.charAt(0).toUpperCase() + type.slice(1) + 'Entry');
            } else {
                return null;
            }
        }
    },
    mounted() {
        this.$el.parentNode.parentNode.addEventListener('scroll', this.onViewContentScrolled);
        if (window.innerWidth < this.$globalData.mobileBreakpoint) {
            this.isMobile = true
        }
        window.addEventListener('resize', this.onWindowResize);
    },
    updated() {
        this.initInternalLinks(this.$refs.entryContent);
        this.$nextTick(() => {
            this.initContentImages(this.$refs.entryContent);
        });
    },
    unmounted() {
        if (this.$el !== null && this.$el.parentNode !== null) { // prevent error on hot reload
            this.$el.parentNode.parentNode.removeEventListener('scroll', this.onViewContentScrolled);
        }
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        async initInternalLinks(context) {
            if (context && !this.internalLinksInited) {
                this.internalLinksInited = true;
                const entryContentLinks = context.getElementsByTagName('a');
                const { hostname } = new URL(process.env.VUE_APP_BASE_URL);
                for (var i = 0; i < entryContentLinks.length; i++) {
                    const link = entryContentLinks[i];
                    if (link.hostname == hostname && link.parentElement.nodeName.toLowerCase() !== 'figure') {
                        link.addEventListener('click', this.onInternalLinkClicked);
                        let linkClass = 'default-internal-Link';
                        const linkPaths = link.pathname.split('/');
                        if ((linkPaths[1] == 'en' && (linkPaths[2] == 'archiv' || linkPaths[2] == 'archive') && linkPaths.length >= 4) || ((linkPaths[1] == 'archiv' || linkPaths[1] == 'archive') && linkPaths.length >= 3)) {
                            if (this.$globalData.isTouchDevice) {
                                linkClass = 'hover-preview-image-link';
                            } else {
                                try {
                                    const { data } = await fetchArchiveEntryBySlug(linkPaths[1] == 'en' ? linkPaths[3] : linkPaths[2]);
                                    const archiveEntryMedia = data['digital_object'];
                                    if (typeof archiveEntryMedia !== 'undefined') {
                                        if ((archiveEntryMedia['media_type'] == 'Bild' || archiveEntryMedia['media_type'] == 'Text' || archiveEntryMedia['media_type'] == 'Video' || archiveEntryMedia['media_type'] == 'Image') && archiveEntryMedia['reference_url'].length) {
                                            this.hoverImages[i] = {
                                                src: archiveEntryMedia['media_type'] == 'Video' ? archiveEntryMedia['thumbnail_url'] : archiveEntryMedia['reference_url'],
                                                show: false
                                            };
                                            const that = this;
                                            const imgKey = i;
                                            link.addEventListener('mouseenter', () => {
                                                that.hoverImageMouseEnter(imgKey);
                                            });
                                            link.addEventListener('mouseleave', () => {
                                                that.hoverImageMouseLeave(imgKey);
                                            });
                                            linkClass = 'hover-preview-image-link';
                                        }
                                    }
                                } catch(err) {
                                    console.log(err); // TypeError: failed to fetch
                                }
                            }
                        }
                        link.classList.add(linkClass)
                    }
                }
            }
        },
        initContentImages(context) {
            if (context && !this.contentImagesInited) {
                this.contentImagesInited = true;
                const contentImgFigures = context.getElementsByTagName('figure');
                const radians = (this.contentImageRotation/180)*Math.PI;
                for (var i = 0; i < contentImgFigures.length; i++) {
                    const contentImgFigure = contentImgFigures[i];
                    const contentImg = contentImgFigure.getElementsByTagName('img')[0];

                    // create wrapper and append container
                    const contentImgWrapper = document.createElement('div');
                    contentImgWrapper.classList.add('content-image-wrapper');
                    contentImgFigure.parentNode.insertBefore(contentImgWrapper, contentImgFigure);
                    contentImgWrapper.appendChild(contentImgFigure);

                    contentImg.addEventListener("load", function() {
                        // calculate rotated image and figure dimensions
                        const imgWidth = contentImg.naturalWidth;
                        const imgHeight = contentImg.naturalHeight;
                        const imgRatio = imgHeight/imgWidth;
                        const rotatedImgWidth = 100/(Math.cos(radians) +( imgRatio*Math.sin(radians)));
                        const rotatedImgFigureHeight = rotatedImgWidth*(Math.sin(radians) +( imgRatio*Math.cos(radians)));

                        // set styles
                        contentImg.style.width = rotatedImgWidth+'%';
                        contentImg.style.position = 'absolute';
                        contentImgFigure.style.paddingBottom = rotatedImgFigureHeight+'%';
                        contentImg.classList.add('loaded');
                    });
                }
            }
        },
        scrollToHash(e) {
            e.preventDefault();
            const targetSelector = e.target.hash;
            const scrollContainer = this.$el.parentNode.parentNode;
            const targetEl = scrollContainer.querySelector(targetSelector);
            let scrollOffset = 0;
            if (targetEl) {
                scrollOffset = targetEl.offsetTop;
            }
            this.$el.parentNode.parentNode.scrollTo({
                top: scrollOffset,
                behavior: "smooth"
            });
        },
        onInternalLinkClicked(e) {
            e.preventDefault();
            this.$router.push(e.target.pathname+e.target.search);
        },
        hoverImageMouseEnter(imgKey) {
            this.hoverImages[imgKey].show = true;
        },
        hoverImageMouseLeave(imgKey) {
            this.hoverImages[imgKey].show = false;
        },
        onViewContentScrolled(e) {
            if (!this.isMobile) {
                this.lastKnownScrollPosition = e.target.scrollTop;
                if (!this.scrollTicking) {
                    const that = this;
                    window.requestAnimationFrame(function() {
                        if (that.$el && that.$el.parentNode) { // prevent hot reload error
                            that.showToTopLink = that.lastKnownScrollPosition > (that.$refs.pageTitle.clientHeight + that.$el.offsetTop + (that.entry.type == 'showcase' ? that.$el.parentNode.parentNode.parentNode.offsetTop : 0));
                            if (that.entry.type == 'showcase') {
                                that.sidebarScrolling = that.$refs.sidebarColumn.getBoundingClientRect().bottom < (that.$el.parentNode.parentNode.parentNode.offsetTop + that.$refs.sidebarLinks.clientHeight + that.$refs.sidebarColumn.offsetTop);
                            }
                        }
                        that.scrollTicking = false;
                    });
                    this.scrollTicking = true;
                }
            }
        },
        onWindowResize() {
            if (this.resizeT){
                clearTimeout(this.resizeT);
            }
            const that = this;
            this.resizeT = setTimeout(function() {
                if (window.innerWidth < that.$globalData.mobileBreakpoint) {
                    that.isMobile = true;
                } else {
                    that.isMobile = false;
                }
            }, 200);
        },
    }
}
</script>
