<template>
    <div class="c-video-player" >
        <button class="custom-play-button" :class="poster ? null : 'no-poster-img'" v-if="!player" @click="startStopVideo" :style="{ backgroundImage: poster ? 'url(' + poster + ')' : 'none' }">
            <svg class="custom-play-svg" data-name="play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.88 106.68" width="80"><defs><clipPath id="a"><path fill="none" d="M0 0h80.88v106.68H0z"/></clipPath></defs><g clip-path="url(#a)"><path stroke="#fff" stroke-width="4.5" fill="none" d="M2.25 4.18v98.32l74.54-49.16L2.25 4.18z"/></g></svg>
        </button>
        <video :controls="player" class="player" :poster="poster ? poster : null">
            <source v-if="srcMp4" :src="srcMp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    name: 'VideoPlayer',
    props: {
        srcMp4: String,
        poster: String,
    },
    data() {
        return {
            player: false,
        }
    },
    methods: {
        startStopVideo(){
            this.player = !this.player;
            this.$el.querySelector(".player").play();
        }
    }
}
</script>
